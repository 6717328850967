<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <b-card>          
          <div class="table-responsive">  
            <h5 class="mb-2">{{ tournament.name }}</h5>         
            <table class="table table-striped">
              <thead>
                  <tr>
                      <th>Jugador</th>
                      <th>Puntos totales</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="item,index in players" :key="index">                
                      <td>{{ index }}</td>                 
                      <td>{{ item }}</td>                 
                  </tr>                  
              </tbody>
          </table>
          </div>
        </b-card>
        <a :href="'/player-rankings'" style="display: block!important;"class="float-right d-block btn btn-warning">Volver a rankings</a>
      
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { syncRef } from '@vueuse/core';
import { BRow, BCardHeader, BCardTitle, BCardBody, BCol, BCard, BCardText, BLink, BButton, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BAlert, BTable  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BLink, BImg, BButton, BMedia, BAvatar, BMediaAside, BMediaBody, BAlert, BTable  },
  directives: {
    Ripple,
  },
  data() {
    return {    
      tournament_id : this.$route.params.tournament,
      tournament: null,
      players: null,
    }
  },
  created() {
    this.$http.get("/api/getTournament?id=" + this.tournament_id).then((response) => {
      this.tournament = response.data;
   
      console.log(this.tournament.name)
    })

    this.$http.get("/api/getTournamentPoints?tournament_id="+this.tournament_id).then((response) => {
      this.players = response.data;
      this.show = false
    })
  }, 
  methods: {
   
  
  },
  computed: {
    
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>

<style></style>
